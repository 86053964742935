import './App.css';
import ImageGrid from './ImageGrid';
import Header from './Header';
function App() {

  return (
    <div className="App">
      <Header text={"lariat"}/>
      <ImageGrid/>
    </div>
  );
} 

export default App;
